import React, { Component } from "react";
import ProfileComponent from './ProfileComponent';
import '../css/components/ButtonCvComponent.css'; 
import {TweenMax, CSSPlugin} from 'gsap';
import '../css/components/DescriptionComponent.css'; 
const plugins = [ CSSPlugin ];
class ButtonCvComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  loaded: ''
		};
	}
	componentDidMount(props) {
		this.setState({loaded: 'ok'})
	}
	componentDidUpdate(preProps, preState) {
		let buttonTarget = document.getElementsByClassName('button')[0];
		let buttonChild = buttonTarget.children[0];
		buttonTarget.addEventListener('mouseenter', e => {
			TweenMax.to('.profile', 2.2, {
				opacity: 0.4
			});
			TweenMax.to(buttonChild, 0.1, {
				css:{ color:"#48A9A6" }
			});
		});
		buttonTarget.addEventListener('mouseleave', e => {
			TweenMax.to('.profile', 2.2, {
				opacity: 0.16
			});
			TweenMax.to(buttonChild, 0.1, {
				css:{ color:"#ffffff" }
			});
		});
	}
	render() {
		return (
			<div>
				<ProfileComponent />
				<div className="button button--cv button--rotate">
					<a href="file/2019-lucia-cenetiempo-cv.pdf" target="_blank">Download curriculum</a>
				</div>  
			</div>
		);
	}
}
export default ButtonCvComponent;
 