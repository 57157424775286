import React, { Component } from "react";
import '../css/components/HeaderComponent.css'; 
class HeaderComponent extends Component {
	render() {
		return (
			<header>
				<h1>Lucia Cenetiempo <span className="slash">&#47;&#47;</span> <span className="light">Creative Frontend Developer</span></h1>
			</header>  
		);
	}
}
export default HeaderComponent;
 