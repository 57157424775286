import React, { Component } from "react";
import '../css/components/MouseComponent.css'; 
import {TweenMax, CSSPlugin} from 'gsap';

const plugins = [ CSSPlugin ];
class MouseComponent extends Component {
    componentDidMount() {
		window.addEventListener('mousemove', e => {
			TweenMax.to('.mouse', 0.3, {
				css: {
					x: e.clientX,
					y: e.clientY
				  }
			})
		});
		window.addEventListener('mousedown', e => {
			TweenMax.to('.mouse--cursor--circle', 0.1, {
				css:{
					width: '70px',
					height: '70px'
				}
			});
		});
		window.addEventListener('mouseup', e => {
			TweenMax.to('.mouse--cursor--circle', 0.1, {
				css:{
					width: '30px',
					height: '30px'
				},
				delay: 0.3
			});
		});
	}
	render() {
		return (
			<div className="mouse mouse--cursor">
				<div className="mouse--cursor--circle"></div>
			</div>  
		);
	}
}
export default MouseComponent;
 


