import React, { Component } from "react";
import LazyImage from "./LazyImageComponent";
import '../css/components/ProfileComponent.css'; 
import {TweenMax, CSSPlugin} from 'gsap';

const plugins = [ CSSPlugin ];
class ProfileComponent extends Component {
	componentDidMount() {
		TweenMax.to('.profile', 3, {
			opacity: 0.16,
			y: 0
		});
		window.addEventListener('scroll', function(e) {
			TweenMax.to('.profile', 2, {
				y: '-'+window.scrollY
			});
		});
	}
	render() {
		return (
			<picture className="profile">
				<LazyImage
					srcset="assets/img/profile.jpg 200w, assets/img/profile.jpg 400w"
					src="assets/img/profile.jpg"
				/>
			</picture>  
		);
	}
}
export default ProfileComponent;
 


