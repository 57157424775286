import React, { Component } from "react";
import '../css/components/CurriculumComponent.css'; 
import {TimelineMax, TweenMax, CSSPlugin} from 'gsap';
import ScrollMagic from "../config/scrollMagic"; 

const plugins = [ CSSPlugin ];
class CurriculumComponent extends Component {
	constructor(props){
		super(props);
		this.cvList = [
			{
				"start": "2019, May",
				"end": "current position",
				"rule": "Senior Frontend Developer",
				"agency": "Kettydo+",
				"location": "Milan"
			},
			{
				"start": "2016, March",
				"end": "2019, May",
				"rule": "Senior Frontend Developer",
				"agency": "h-farm",
				"location": "Milan"
			},
			{
				"start": "2011, June",
				"end": "2017, December",
				"rule": "Freelance Web Designer",
				"agency": "laFactory",
				"location": "Milan"
			},
			{
				"start": "2015, August",
				"end": "2016, March",
				"rule": "Frontend Developer & Designer",
				"agency": "SocialStreaming",
				"location": "Milan"
			},
			{
				"start": "2015, August",
				"end": "2016, March",
				"rule": "Frontend Developer & Designer",
				"agency": "SocialStreaming",
				"location": "Milan"
			},
			{
				"start": "2015, March",
				"end": "2015, August",
				"rule": "Web Designer & Social Media Manager",
				"agency": "The Fan Simone Ranieri Events",
				"location": "Milan"
			},
			{
				"start": "2014, June",
				"end": "2014, September",
				"rule": "Web Designer",
				"agency": "Behind the digital",
				"location": "Milan"
			},
			{
				"start": "2013, November",
				"end": "2014, March",
				"rule": "Freelance Web Designer",
				"agency": "MockuApp",
				"location": "www"
			},
			{
				"start": "2009, October",
				"end": "2014, May",
				"rule": "Web Designer",
				"agency": "BrainComputing S.p.A.",
				"location": "Rome"
			},
			{
				"start": "2009, July",
				"end": "2009, October",
				"rule": "Web Designer",
				"agency": "Virtago Communication S.r.l",
				"location": "Salerno"
			},
			{
				"start": "2009, July",
				"end": "2009, October",
				"rule": "internship web development & graphic",
				"agency": "Emmegi Grafica",
				"location": "Rome"
			},
			{
				"start": "2009, January",
				"end": "2009, March",
				"rule": "internship videographic",
				"agency": "Emmegi Grafica",
				"location": "Rome"
			}
		];
		
		this.state = {
			cvLoaded: ''
		};
	}
	componentDidMount(){
		this.setState({cvLoaded: 'yes'})
	}
	componentDidUpdate(preProps, preState) {
		if (this.state !== preState) {
			var cvTimeline = new TimelineMax();
			var cvListTimeline = new TimelineMax();
			var tweenCvLine = TweenMax.to('.line__curriculum', 2.2, {
				width:"100%",
				delay:0
			});
			var twenCvContent = TweenMax.to(".section--curriculum__content", .5, {
				opacity: 1,
				top: 0
			});
			cvListTimeline.staggerTo( '.section--curriculum__list--row ', 0.5, { 
				opacity: 1,
				borderBottom: 1
			}, 0.5);
			cvTimeline.add(twenCvContent).add(cvListTimeline).add(tweenCvLine);
			var cvScene = new ScrollMagic.Controller();
			new ScrollMagic.Scene({triggerElement:'.controller--showCv', offset: 10 })
				.on('start', function () {console.log('scrollmagic cv list started');})
				.setTween(cvTimeline)
				.addTo(cvScene);
		}
	}
	render() {
		return (
			<section className="section section--curriculum">
				<span className="controller controller--showCv"></span>	
				<div className="section--curriculum__content">
					<h2>I worked for:</h2>
				</div>	
				<div className="line__curriculum"></div>
				<div className="section--curriculum__list">
				{
					this.cvList.map(function(cvSingle, index){
						return (
							<div className="section--curriculum__list--row" key={index}>
								<div className="col col--date">{cvSingle.start} &#47;&#47; {cvSingle.end}</div>
								<div className="col col--rule">{cvSingle.rule}</div>
								<div className="col col--agency">{cvSingle.agency}</div>
								<div className="col col--location">{cvSingle.location}</div>
							</div>
						)
					})
				}
				</div>
			</section>
		);
	}	
}
export default CurriculumComponent;