import React, { Component } from "react";
import '../css/components/DescriptionComponent.css'; 
import {TweenMax, CSSPlugin} from 'gsap';
import ScrollMagic from "../config/scrollMagic"; 

const plugins = [ CSSPlugin ];
class DescriptionComponent extends Component {
	constructor(props){
		super(props);
		this.line = null;
		this.lineTween = null;
	}
	componentDidMount(){
		TweenMax.to('.line', 2.2, {
			width:"100%",
			delay:2
		});
		TweenMax.to('.intro', 2.2, {
			opacity: 1,
			top: 0,
			delay:2.5
		});
        var controller = new ScrollMagic.Controller();
        var startPoint = ".controller--showDescription";
		var item = ".section--description";
    
		new ScrollMagic.Scene({triggerElement:startPoint, duration: 400, offset: 10 })
			.on('start', function () {console.log('scrollmagic started');})
			.setTween(item, 2.2, {opacity: 1, top: 0})
			.addTo(controller);

    }
	render() {
		return (
			<div>
				<section className="section section--intro">
					<div className="line"></div>
					<p className="intro">Specialized in Frontend, Wordpress and Microinteraction Development.<br/>
						<strong>I love making things work!</strong></p>
				</section>
				<section className="section section--description"> 
					<span className="controller controller--showDescription"></span>
					<p>Frontend developer for stubbornness. I studied graphic design and advertising before making the switch. I decided to understand and move on to the dark side.<br/>My background, however, allows me to be very sensitive to design and usability of web projects.</p>
				</section>
			</div>
		);
	}
}
export default DescriptionComponent;