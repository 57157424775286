import React from 'react';
import MouseComponent from './components/MouseComponent';
import ButtonCvComponent from './components/ButtonCvComponent';
import WrapComponent from './components/WrapComponent';
import HeaderComponent from './components/HeaderComponent';
import './css/main.css';
function App() {
    return (
      
        <div className="App">
          <MouseComponent />
          <ButtonCvComponent />
          <HeaderComponent />
          <WrapComponent />
        </div>
    );
}

export default App;
