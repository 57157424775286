import React, { Component } from "react";
import DescriptionComponent from './DescriptionComponent';
import ClientsComponent from './ClientsComponent';
import CurriculumComponent from './CurriculumComponent';
// import Scrollbar from 'react-smooth-scrollbar';
class WrapComponent extends Component {
	render() {
		return (
		// <Scrollbar
		// 	damping={0.1}
		// 	thumbMinSize={8}
		// 	renderByPixels={true}
		// 	continuousScrolling={true}
		//   >
				<div id="wrap">
					<DescriptionComponent />
					<ClientsComponent />
					<CurriculumComponent />
				</div>
		// </Scrollbar>	
		);
	}
}
export default WrapComponent; 
 