import React, { Component } from "react";
import '../css/components/ClientsComponent.css'; 
import {TimelineMax, TweenMax, CSSPlugin} from 'gsap';
import ScrollMagic from "../config/scrollMagic"; 

const plugins = [ CSSPlugin ];
class ClientsComponent extends Component {
	constructor(props){
		super(props);
		this.clientList= [
			"Alitalia",
			"Altrocosumo",
			"Asus",
			"BPER",
			"Barilla",
			"Cantine Maschio",
			"Coop",
			"Edenred",
			"IoDonna",
			"Lavazza",
			"Luxottica",
			"Mulino Bianco",
			"Pampers",
			"Tim Business",
			"Tirrenia",
			"Trony",
			"Trussardi",
			"Valcucine",
			"Vigorsol",
			"Zurich Connect"
		];
		
		this.state = {
			clientsLoaded: ''
		};
	}
	componentDidMount(){
		this.setState({clientsLoaded: 'yes'})
	}
	componentDidUpdate(preProps, preState) {
		if (this.state !== preState) {
			var clientsTimeline = new TimelineMax();
			var clientsListTimeline = new TimelineMax();
			var tweenCLine = TweenMax.to('.line__clients', 2.2, {
				width:"100%",
				delay:0
			});
			var twenCContent = TweenMax.to(".section--clients__content", .1, {
				opacity: 1,
				top: 0
			});
			clientsListTimeline.staggerTo( '.single--client', 0.2, { 
				opacity: 1
			}, 0.2);
			clientsTimeline.add(twenCContent).add(clientsListTimeline).add(tweenCLine);
			var clientsScene = new ScrollMagic.Controller();
			new ScrollMagic.Scene({triggerElement:'.controller--showClients', offset: 10 })
				.on('start', function () {console.log('scrollmagic client list started');})
				.setTween(clientsTimeline)
				.addTo(clientsScene);
		}
	}
	render() {
		return (
			<section className="section section--clients">
				<span className="controller controller--showClients"></span>	
				<div className="section--clients__content">
					<h2>I worked on pieces of:</h2>
				</div>	
				<div className="line__clients"></div>
				<ul className="section--clients__list">
				{
					this.clientList.map(function(client, index){
						return <li className="single--client" key={ index }>{client} <span>&#47;&#47;</span> </li>;
					})
				}
				</ul>
			</section>
		);
	}	
}
export default ClientsComponent;